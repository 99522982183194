/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

    // Toggle open and close nav styles on click
    $('#nav-toggle').click(function(e) {
      e.preventDefault();
      $('nav.nav-primary').fadeToggle(300);
      $(this).find('.nav-toggle-bars').toggleClass('active');
    });

    //caches a jQuery object containing the header element
    var header = $(".banner");
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 140) {
            header.removeClass('full').addClass('restrict-height');
            $('.mouse-move').fadeOut(200);
        } else {
            header.removeClass("restrict-height").addClass('full');
            $('.mouse-move').fadeIn(200);
        }   
    });




function blokkenheight() {

  if ($(window).width() > 575) {
   
  $('.diensten').each(function(){
  
    $('.diensten-blok-content p',this).height('auto');

        var highestBox = 0;
        $('.diensten-blok-content p', this).each(function(){

            if($(this).height() > highestBox) {
               highestBox = $(this).height(); 
             }
        });  

        $('.diensten-blok-content p',this).height(highestBox);


  
    $('.diensten-blok-content h5',this).height('auto');

        var highestTitle = 0;
        $('.diensten-blok-content h5', this).each(function(){

            if($(this).height() > highestTitle) {
               highestTitle = $(this).height(); 
              }
        });  

        $('.diensten-blok-content h5',this).height(highestTitle);

  });
   
  } else {
    $('.diensten-blok-content p').height('auto');
    $('.diensten-blok-content h5').height('auto');
  }

}
blokkenheight();

function itemsheight() {

  if ($(window).width() > 767) {
   
  $('.row-items').each(function(){
  
    $('.item-block .item-block-content',this).height('auto');

        var highestBox = 0;
        $('.item-block .item-block-content', this).each(function(){

            if($(this).height() > highestBox) {
               highestBox = $(this).height(); 
             }
        });  

        $('.item-block .item-block-content',this).height(highestBox);

  });
   
  } else {
    $('.item-block .item-block-content').height('auto');
  }

}
itemsheight();

  $(window).resize(function() {
    blokkenheight();
    itemsheight();
  });

$('.slider-header').on('init', function(event, slick){
  console.log('slider was initialized');
  $('.herovid').each(function() {
    $(this).trigger('play');
  });
});

if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
 $('video.herovid').remove();
}

$('.slider-header').slick({
  dots: true,
  autoplay: true,
  autoplaySpeed: 6000,
  nextArrow: '<i class="fa fa-chevron-right slick-arrow arrow-right"></i>',
  prevArrow: '<i class="fa fa-chevron-left slick-arrow arrow-left"></i>',
  appendArrows: '.slider-header-arrows .container',
});

$('.slider-referenties').slick({
  dots: true,
  //autoplay: true,
  //autoplaySpeed: 6000,
  nextArrow: '<i class="fa fa-chevron-right slick-arrow arrow-right"></i>',
  prevArrow: '<i class="fa fa-chevron-left slick-arrow arrow-left"></i>',
  slidesToShow: 2,
  slidesToScroll: 2,
  appendArrows: '.slider-referenties-arrows',
  appendDots: '.slider-referenties-dots',
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
});

$('.slider-referenties').on('afterChange', function(){
  itemsheight();
});



    

    $('.load-more-wrapper').each(function(){

    var ajaxUrl = bobz.ajax_url;

    var button = $('.load-more-btn', this);
    var buttonwrap = $('.load-more', this);
    var contentwrap = $('.row-items', this);

    var maxpages = $(button).attr('data-pages');

    var page = 1; // What page we are on.
    var ppp = $(button).attr('data-posts'); // Post per page

    $(button).on("click",function(event){ // When btn is pressed.
        event.preventDefault();
        $(button).attr("disabled",true); // Disable the button, temp.
        $.post(ajaxUrl, {
            action:"more_post_ajax",
            offset: (page * ppp),
            ppp: ppp,
            posttype: $(button).attr('data-type')
        }).success(function(posts){
            page++;
            if ( maxpages == page ) {
              $(buttonwrap).hide();
            }
            $(contentwrap).append(posts); // CHANGE THIS!
            $(button).attr("disabled",false);
            itemsheight();
        });

   });

   });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
